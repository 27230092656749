@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}



::-webkit-scrollbar-track
{
	
	border-radius: 10px;
	background-color: transparent;
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.2);
	background-color: #d3d3d3;
}
